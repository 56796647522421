<template>
  <div style="display: flex; flex-direction: row; max-width: 1200px; margin: 0 auto; width: 100%;">
    <router-link :to="{name: 'homepage'}">
      <img src="../../resources/icon.png" alt="MakeWise logo" style="height: 5rem;" />
    </router-link>
    <span style="font-size: 1.2em; font-weight: bold; align-self: center; padding-left: 1rem;">
      Plate.Vision demo
    </span>
  </div>
</template>


<script setup lang="ts">

</script>