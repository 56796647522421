import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import './registerServiceWorker';
import { createI18n } from 'vue-i18n';
//import { globalizationList } from './data/translations';
import pt from './data/pt.json';
import en from './data/en.json';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);


const i18n = createI18n<false>({
  globalInjection: true,
  legacy: false,
  locale: 'en', 
  fallbackLocale: 'en', 
  messages: {
    'pt': pt,
    'en': en
  } 
  });
  


const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(router);
  
router.isReady().then(() => {
  app.mount('#app');
});