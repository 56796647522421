import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
}

export const usePhotoGallery = () => {
  
  const takePhoto = ():Promise<Photo> => {
    return Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 90,
      height: 1080,
    })
  };
  
  const uploadPhoto = ():Promise<Photo> => {
    return Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Photos,
      quality: 90,
      height: 1080,
    })
  };
  
  async function resizePhoto (base64: string, maxWidth: number, maxHeight: number):Promise<string>{
    
    // Max size for thumbnail
    if(typeof(maxWidth) === 'undefined')  maxWidth = 1920;
    if(typeof(maxHeight) === 'undefined')  maxHeight = 1080; 
    
    // Create and initialize two canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const canvasCopy = document.createElement("canvas");
    const copyContext = canvasCopy.getContext("2d");
    
    // Create original image
    const img = new Image();
    img.src = base64;
    await img.decode();
    
    // Determine new ratio based on max size
    let ratio = 1;
    if(img.width > maxWidth)
    ratio = maxWidth / img.width;
    else if(img.height > maxHeight)
    ratio = maxHeight / img.height;
    // Draw original image in second canvas
    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    
    copyContext?.drawImage(img, 0, 0);
    
    // Copy and resize second canvas to first canvas
    canvas.width = img.width * ratio;
    canvas.height = img.height * ratio;
    ctx?.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);
    
    const newbase64 = canvas.toDataURL('image/jpeg', 0.9);
    
    return newbase64.slice(newbase64.indexOf(',')+1);;    
  }

  
  return {
    takePhoto,
    uploadPhoto,
    resizePhoto,
  };
};