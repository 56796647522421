"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Plate.Vision Demo api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: hello@makewise.pt
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileApi = exports.MobileApiFactory = exports.MobileApiFp = exports.MobileApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("./common");
// @ts-ignore
const base_1 = require("./base");
/**
 * MobileApi - axios parameter creator
 * @export
 */
const MobileApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredits: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/mobile/credits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Base64RecognizeRequest} base64RecognizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recognizeByBase64: (base64RecognizeRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'base64RecognizeRequest' is not null or undefined
            (0, common_1.assertParamExists)('recognizeByBase64', 'base64RecognizeRequest', base64RecognizeRequest);
            const localVarPath = `/api/mobile/recognize/base64`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(base64RecognizeRequest, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} plateLocation
         * @param {string} toDetectMakeAndModel
         * @param {File} image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recognizeByFile: (plateLocation, toDetectMakeAndModel, image, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'plateLocation' is not null or undefined
            (0, common_1.assertParamExists)('recognizeByFile', 'plateLocation', plateLocation);
            // verify required parameter 'toDetectMakeAndModel' is not null or undefined
            (0, common_1.assertParamExists)('recognizeByFile', 'toDetectMakeAndModel', toDetectMakeAndModel);
            // verify required parameter 'image' is not null or undefined
            (0, common_1.assertParamExists)('recognizeByFile', 'image', image);
            const localVarPath = `/api/mobile/recognize/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            // http bearer authentication required
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (plateLocation !== undefined) {
                localVarQueryParameter['plateLocation'] = plateLocation;
            }
            if (toDetectMakeAndModel !== undefined) {
                localVarQueryParameter['toDetectMakeAndModel'] = toDetectMakeAndModel;
            }
            if (image !== undefined) {
                localVarFormParams.append('image', image);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RegisterRequest} registerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: (registerRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'registerRequest' is not null or undefined
            (0, common_1.assertParamExists)('register', 'registerRequest', registerRequest);
            const localVarPath = `/api/mobile/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(registerRequest, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} verificationToken
         * @param {boolean} [doRedirect]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: (verificationToken, doRedirect, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'verificationToken' is not null or undefined
            (0, common_1.assertParamExists)('verifyToken', 'verificationToken', verificationToken);
            const localVarPath = `/api/mobile/tokens/verification/{verificationToken}`
                .replace(`{${"verificationToken"}}`, encodeURIComponent(String(verificationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (doRedirect != null) {
                localVarHeaderParameter['doRedirect'] = typeof doRedirect === 'string'
                    ? doRedirect
                    : JSON.stringify(doRedirect);
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.MobileApiAxiosParamCreator = MobileApiAxiosParamCreator;
/**
 * MobileApi - functional programming interface
 * @export
 */
const MobileApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.MobileApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredits(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCredits(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {Base64RecognizeRequest} base64RecognizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recognizeByBase64(base64RecognizeRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.recognizeByBase64(base64RecognizeRequest, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {string} plateLocation
         * @param {string} toDetectMakeAndModel
         * @param {File} image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recognizeByFile(plateLocation, toDetectMakeAndModel, image, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.recognizeByFile(plateLocation, toDetectMakeAndModel, image, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {RegisterRequest} registerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.register(registerRequest, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {string} verificationToken
         * @param {boolean} [doRedirect]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(verificationToken, doRedirect, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyToken(verificationToken, doRedirect, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.MobileApiFp = MobileApiFp;
/**
 * MobileApi - factory interface
 * @export
 */
const MobileApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.MobileApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCredits(options) {
            return localVarFp.getCredits(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Base64RecognizeRequest} base64RecognizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recognizeByBase64(base64RecognizeRequest, options) {
            return localVarFp.recognizeByBase64(base64RecognizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} plateLocation
         * @param {string} toDetectMakeAndModel
         * @param {File} image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recognizeByFile(plateLocation, toDetectMakeAndModel, image, options) {
            return localVarFp.recognizeByFile(plateLocation, toDetectMakeAndModel, image, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {RegisterRequest} registerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest, options) {
            return localVarFp.register(registerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} verificationToken
         * @param {boolean} [doRedirect]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(verificationToken, doRedirect, options) {
            return localVarFp.verifyToken(verificationToken, doRedirect, options).then((request) => request(axios, basePath));
        },
    };
};
exports.MobileApiFactory = MobileApiFactory;
/**
 * MobileApi - object-oriented interface
 * @export
 * @class MobileApi
 * @extends {BaseAPI}
 */
class MobileApi extends base_1.BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    getCredits(options) {
        return (0, exports.MobileApiFp)(this.configuration).getCredits(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Base64RecognizeRequest} base64RecognizeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    recognizeByBase64(base64RecognizeRequest, options) {
        return (0, exports.MobileApiFp)(this.configuration).recognizeByBase64(base64RecognizeRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} plateLocation
     * @param {string} toDetectMakeAndModel
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    recognizeByFile(plateLocation, toDetectMakeAndModel, image, options) {
        return (0, exports.MobileApiFp)(this.configuration).recognizeByFile(plateLocation, toDetectMakeAndModel, image, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {RegisterRequest} registerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    register(registerRequest, options) {
        return (0, exports.MobileApiFp)(this.configuration).register(registerRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} verificationToken
     * @param {boolean} [doRedirect]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileApi
     */
    verifyToken(verificationToken, doRedirect, options) {
        return (0, exports.MobileApiFp)(this.configuration).verifyToken(verificationToken, doRedirect, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.MobileApi = MobileApi;
