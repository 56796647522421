import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { Storage } from '@ionic/storage';
import HomePage from '@/views/home/HomePage.vue';
import SignIn from '@/views/register/SignIn.vue';
import PreferencesPage from '@/views/home/PreferencesPage.vue';
import TermsAndConditions from '@/views/register/TermsAndConditions.vue';
import ReportPage from '@/views/reports/ReportPage.vue';
import ReportsList from '@/views/reports/ReportsList.vue';
import EmailConfirm from '@/views/register/EmailConfirm.vue';
import MainPage from '@/views/home/MainPage.vue';
import RegistrationProcess from '@/views/register/RegistrationProcess.vue';
import StartGuide from '@/views/home/StartGuide.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/register',
        component: RegistrationProcess,
        children: [
            {
                path: '',
                component: TermsAndConditions,
            },
            {
                path: 'terms_conditions',
                name: 'terms',
                component: TermsAndConditions,
            },
            {
                path: 'email_confirmation',
                name: 'emailConfirm',
                component: EmailConfirm,
            },
            {
                path: 'sign_in',
                name: 'sign',
                component: SignIn,
            },
        ],
    },
    {
        path: '/homepage',
        component: MainPage,
        children: [
            {
                path: 'preferences',
                name: 'preferences',
                component: PreferencesPage,
            },
            {
                path: '',
                name: 'homepage',
                component: HomePage,
            },
            {
                path: '/homepage/reports/:index',
                name: 'report',
                component: ReportPage,
                props: true,
            },
            {
                path: 'reports',
                component: ReportsList,
                name: 'reports',
            },
            {
                path: 'start_guide',
                name: 'startGuide',
                component: StartGuide,
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: () => ({ name: 'homepage' }),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL + (process.env.VUE_APP_ROUTER_SUB_DIR || '')),
    routes,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach(async (to, from) => {
    const storage = new Storage();
    await storage.create();
    const emailConfirmed = await storage.get('emailConfirmed');

    if (emailConfirmed) {
        if (to.name == 'terms' || to.name == 'sign' || to.name == 'emailConfirm') {
            return { name: 'homepage' };
        }
    } else if (to.name != 'terms' && to.name != 'sign' && to.name != 'emailConfirm') {
        return { name: 'terms' };
    }
});

export default router;
